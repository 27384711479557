.wrapper {
}

.title {
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  margin: 0;
  padding: 5px 0 3px 0;
}

.row {
  display: flex;
}

.rowLabel {
  width: 100%;
  max-width: 200px;
}

.rowValue {
  margin-left: 10px;
}
