.wrapper {
  margin-top: 20px;
  @media (max-width: 1025px) {
    padding: 40px;
  }
}

.field {
  margin: 20px 0;
}

.error {
  color: red;
}

.formWrapper {
  @media (max-width: 1025px) {
    padding: 40px 0;
  }
}

.costEstimateButton {
  margin-top: 20px;
}
